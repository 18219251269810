import { useEffect } from 'react';
import { useRouter } from 'next/router';

import PublicLayout from '@app/components/layouts/public-layout';
import { useAuth } from '@app/context/useAuth';
import Loading from '@app/components/loading';

export default function Home(): JSX.Element {
  const { isReady, user } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (isReady) {
      if (user) {
        router.push('/tickets/book-tickets');
      } else {
        router.push('/login');
      }
    }
  }, [user, router, isReady]);

  return (
    <PublicLayout pageTitle="loading...">
      <Loading />
    </PublicLayout>
  );
}
